import axios from '@axios'
import { db, auth, storage } from '@/Firebase/Firebase.service'
import { collectionGroup, collection, getDocs, getDoc, doc, addDoc , where, query } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        var currentCompany = JSON.parse(localStorage.getItem('company'))
        getDocs(collection(db, `/companies/${currentCompany.company.id}/memberships`))
          .then((membershipsCollection) => {
            //set lower/higher range for search
            var lowerRange = queryParams.options.itemsPerPage * (queryParams.options.page - 1);
            var higherRange = lowerRange + queryParams.options.itemsPerPage;

            var userDocs = []
            membershipsCollection.forEach((membership) => {
              userDocs.push(membership)
            })
            userDocs = userDocs.slice(lowerRange, higherRange);

            var filteredData = []
            var total = 0

            new Promise((resolveforEach, rejectforEach) => {
              userDocs.forEach((membership) => {
                if (membership.data().avatarImage == "") {
                  filteredData.push({
                    avatar: "",
                    company: "",
                    contact: "",
                    country: "",
                    currentPlan: "",
                    email: membership.data().email,
                    fullName: "",
                    id: membership._key.path.toString(),
                    role: membership.data().roleName,
                    status: membership.data().status,
                    username: membership.data().username,
                  })
                  total++
                }
                else {
                  getDownloadURL(ref(storage, membership.data().avatarImage)).then((avatarUrl) => {
                    filteredData.push({
                      avatar: avatarUrl,
                      company: "",
                      contact: "",
                      country: "",
                      currentPlan: "",
                      email: membership.data().email,
                      fullName: "",
                      id: membership._key.path.toString(),
                      role: membership.data().roleName,
                      status: membership.data().status,
                      username: membership.data().username,
                    })
                    total++
                    if (total == userDocs.length) {
                      resolveforEach()
                    }
                  })
                }
              })
              if (total == userDocs.length) {
                resolveforEach()
              }

            }).then(() => { 
              var data = {
                filteredData: filteredData,
                total: membershipsCollection.size
              }
              var response =
              {
                data: data
              }
              resolve(response)
            })
          }).catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        try {
          var membershipRef = doc(db, id)
          getDoc(membershipRef).then((memberDoc) => {

            new Promise((resolveFetchUser, rejectFetchUser) => {
              if (memberDoc.data().avatarImage == "") {
                var data = {}
                data.avatar = memberDoc.data().avatarImage
                data.company = ""
                data.contact = ""
                data.country = ""
                data.currentPlan = ""
                data.email = memberDoc.data().email
                data.fullName = ""
                data.id = memberDoc.data().user
                data.memberid = memberDoc.id
                data.role = memberDoc.data().roleName
                data.roleDoc = memberDoc.data().role
                data.status = memberDoc.data().status
                data.username = memberDoc.data().username
                data.currentPlan = ""
                resolveFetchUser(data)
              }
              else {
                getDownloadURL(ref(storage, memberDoc.data().avatarImage)).then((avatarUrl) => {
                  var data = {}
                  data.avatar = avatarUrl
                  data.company = ""
                  data.contact = ""
                  data.country = ""
                  data.currentPlan = ""
                  data.email = memberDoc.data().email
                  data.fullName = ""
                  data.id = memberDoc.user
                  data.memberid = memberDoc.data().user
                  data.role = memberDoc.data().roleName
                  data.roleDoc = memberDoc.data().role
                  data.status = memberDoc.data().status
                  data.username = memberDoc.data().username
                  data.currentPlan = ""
                  resolveFetchUser(data)
                })
              }
              }).then((data) => { 
                var data = {
                  data
                }
                var response = {
                  data
                }
                resolve(data)
              })
          })
        } catch (e) {
          console.log(e)
        }

        //axios
        //  .get("/apps/user/users/50")
        //  .then(response => resolve(response))
        //  .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {


        //axios
        //  .post('/apps/user/users', { user: userData })
        //  .then(response => resolve(response))
        //  .catch(error => reject(error))
        //return


        //find user
        const usersQuery = query(collectionGroup(db, 'users'), where('email', '==', userData.email));
        getDocs(usersQuery).then((UsersCollection) => {

          if (UsersCollection.size == 1) {
            //got the user id, just need to get backend user

          //check if company already has this user
          var currentCompany = JSON.parse(localStorage.getItem('company'))
          var companyMembershipref = collection(db, `companies/${currentCompany.company.id}/memberships/`)
          var userDocRef = doc(db, "users", UsersCollection.docs[0].id)
          var companyMembershipQuery = query(companyMembershipref, where('user', '==', userDocRef))
            
          getDocs(companyMembershipQuery).then((companyMembershipDocs) => {
            //if this user does not exist, we can add it
            if (companyMembershipDocs.size == 0) {
            //get user doc for reference
              getDoc(userDocRef).then((userDoc) => {
                var currentCompany = JSON.parse(localStorage.getItem('company'))
              //add user to company members collection
                addDoc(collection(db, `companies/${currentCompany.company.id}/memberships/`), {
                  avatarImage: userDoc.data().avatarImage,
                  email: userDoc.data().email,
                  role: doc(db, `userPermissions/${userData.role}`),
                  roleName: userData.role,
                  user: userDocRef,
                  status: "active",
                  username: userDoc.data().username
                })
                resolve({ error: false, text: `successfully added user ${userDoc.data().email}` })
                console.log("ok")
              })
            }
            else {
              resolve({ error: true, text: "user already exists" })
              console.log("already exists")
            }
          })
          } else {
            resolve({ error: true, text: "No User With That Email"})
          }
        })
      })
    },
  },
}



//import axios from '@axios'
//import { db } from '@/Firebase/Firebase.service'
//import { collection, getDocs, getDoc, doc } from "firebase/firestore"



//export default {
//  namespaced: true,
//  state: {},
//  getters: {},
//  mutations: {},
//  actions: {
//    fetchUsers(ctx, queryParams) {
//      return new Promise((resolve, reject) => {
//        var currentCompany = JSON.parse(localStorage.getItem('company'))
//        getDocs(collection(db, `/Companies/${currentCompany.company.id}/Memberships`))
//          .then((membershipsCollection) => {

//            var filteredData = []
//            var total = 0

//            membershipsCollection.forEach((membership) => {
//              total++
//              filteredData.push({
//                avatar: membership.data().AvatarImage,
//                company: "",
//                contact: "",
//                country: "",
//                currentPlan: "",
//                email: membership.data().Email,
//                fullName: "",
//                id: membership.data().User.path,
//                role: membership.data().Rolename,
//                status: membership.data().Status,
//                username: membership.data().Username,
//              })
//            })
//            var data = {
//              filteredData: filteredData,
//              total: total
//            }
//            var response =
//            {
//              data: data
//            }
//            resolve(response)
//          }).catch(error => reject(error))
//      })
//    },
//    fetchUser(ctx, { id }) {
//      return new Promise((resolve, reject) => {
//        try {


//          var docRef = doc(db, id)
//        } catch (e) {
//          console.log(e)
//        }
//        getDoc(docRef).then((userDoc) => {

//          var userData = {
//            avatar: userDoc.data().AvatarImage,
//            company: "",
//            contact: "",
//            country: "",
//            currentPlan: "",
//            email: userDoc.data().Email,
//            fullName: "",
//            id: userDoc.id,
//            role: userDoc.data().Rolename,
//            status: userDoc.data().Status,
//            username: userDoc.data().Username,
//            currentPlan: "",
//          }

//          var data = {
//            userData
//          }
//          var response = {
//            userData
//          }

//          resolve(response)
//        })
//      })
//    },
//    addUser(ctx, userData) {
//      return new Promise((resolve, reject) => {
//        axios
//          .post('/apps/user/users', { user: userData })
//          .then(response => resolve(response))
//          .catch(error => reject(error))
//      })
//    },
//  },
//}
